import {LitElement, html, css, unsafeCSS} from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import confeti from '../../assets/images/confetti-bg.png'

class InsuranceWelcome extends LitElement {
    static properties = {
        isShow: {
            default: 'false'
        },
        featureList:{
            type: Array,
            attribute: 'feature-list'
        },
        name:{
            type: String, 
            attribute: 'profile-name'
        },
        csrf: {
          type: Object,
          attribute: false,
          state: true,
        }
    };

    constructor() {
        super();   
          
    }

    static styles = css`
        * {
            margin: 0;
            padding: 0;
            border: 0;
            box-sizing: border-box;
        }
        *, ::before, ::after {
            box-sizing: border-box;
            border-width: 0;
            border-style: solid;
            border-color: #ECECEC;
        }
        .content{
            text-align: center;
        }
        .img-conf{
            width: 100%;
            margin-top: -40px;
        }
        .header-text{
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
        }
        .sub-text{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #424242;
            margin: 16px auto 24px;

        }
        .list-container{
            display: flex;
            flex-flow: row wrap;
            width: 894px;
            margin: 0 auto;
            margin-bottom: 44px;
        }
        .icon-item{
            width: 56px;
            height: 56px;
        }
        .list-item{
            display: flex;
            width: 426px;
            margin-bottom: 25px;
            margin-right: 20px;
            justify-content: center;
            border: 1px solid rgb(236, 236, 236);
            border-radius: 8px;
            padding: 16px;
            align-items: center;
        }
        .text-item{
            text-align: left;
            margin-left: 16px;
            justify-content: center;
        }
        .text-title{
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
        }
        .text-description{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #7F7F7F;
        }
        a{
            text-decoration: none;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            background: #3570D2;
            border-radius: 8px;
            padding: 15px 45px;
            margin-top: 44px;
            cursor: pointer;
        }

    `

    connectedCallback(){
        super.connectedCallback()

        this.csrf = {
            ...this.csrf,
            param: document.querySelector('head > meta[name="csrf-param"]')?.content,
            token: document.querySelector('head > meta[name="csrf-token"]')?.content,
        };
    }

    async setFirstLogin(){
        const response = await fetch("/dashboard/deactivate-login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": this.csrf["token"],
            },
            
            credentials: "same-origin",
          });
      
          if (response.ok) {
            // window.location.href = "/dashboard";
            window.location.href = "/members";
          }
    }

    render() {
        return html`
            <img class="img-conf" src="${confeti}">
            <div class="main-container">
                <div class="content">
                    <div class="header-text">Halo ${this.name}, Selamat Datang di <br> Dashboard Proteksi!</div>
                    <div class="sub-text">Fitur-fitur yang sudah bisa Anda manfaatkan:</div>

                    <div class="list-container">
                        ${repeat(
                        this.featureList,
                        (list) => html`
                            <div class="list-item">
                                <img class="icon-item" src="${list.icon}"/>
                                <div class="text-item">
                                    <div class="text-title">
                                        ${list.title} 
                                    </div>
                                    <div class="text-description">${list.description}</div>
                                </div>
                            </div>
                        `
                        )}    
                    </div>
                    <a class="button-blue" @click="${this.setFirstLogin}">Masuk ke Dashboard</a>
                </div>               
                
            </div>
            
            
        `;
    }

    
}

customElements.define('insurance-welcome', InsuranceWelcome);



