import { LitElement, html, css } from 'lit'
import icon_404 from '../../assets/images/404_icon.png'

class InsuranceError extends LitElement {
  static get properties() {
    return {
      title: { type: String },
      message: { type: String },
      ctaButton: { type: String },
      ctaRedirect: { type: String },
    }
  }

  constructor() {
    super()
  }
  firstUpdated() {
    super.firstUpdated()
  }

  render() {
    return html`<div class="container">
      <img src="${icon_404}" class="icon" />
      <div class="title">${this.title}</div>
      <div class="message">${this.message}</div>
      <a class="btn-primary" href="${this.ctaRedirect}">${this.ctaButton}</a>
    </div>`
  }
  static styles = css`
    .btn-primary {
      background: #3570d2;
      color: white;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      padding: 12px 16px;
      border-radius: 8px;
    }
    .btn-primary:hover {
      background-color: #1652b5;
      border-color: #1652b5;
    }
    .btn-primary:active {
      background-color: #003a9e;
      border-color: #003a9e;
    }
    .btn-primary.disabled {
      opacity: 0.4;
      background: #3570d2;
      cursor: not-allowed;
    }
    .btn-primary.disabled:hover {
      opacity: 0.4;
      background: #3570d2;
      cursor: not-allowed;
    }
    .btn-primary.disabled:active {
      opacity: 0.4;
      background: #3570d2;
      cursor: not-allowed;
    }
    .container {
      width: 448px;
      height: 360px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 32px 60px;
      background-color: white;
      border-radius: 12px;
      border: 1px solid
        var(--background-color-color-background-grey-lighter, #ececec);
      background: var(--global-color-white-000, #fff);
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06),
        0px 0px 1px 0px rgba(0, 0, 0, 0.02);
    }
    .title {
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      color: #424242;
      margin-top: 8px;
    }
    .message {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      color: #7f7f7f;
      margin-top: 8px;
      margin-bottom: 32px;
    }
    a {
      text-decoration: none;
    }
    .icon {
      width: 134px;
      align-self: center;
    }
  `
}

customElements.define('insurance-error', InsuranceError)
