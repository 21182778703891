import { LitElement, html, css, nothing } from "lit";

class LoginForm extends LitElement {
  static get properties() {
    return {
      corporateLandingUrl: {
        type: String,
        attribute: "corporate-landing-url",
      },
      loginUrl: {
        type: String,
        attribute: "login-url",
      },
      csrf: {
        type: Object,
        attribute: false,
        state: true,
      },
      data: {
        type: Object,
        attribute: "data",
      },
    };
  }

  constructor() {
    super();

    this.csrf = {};
  }

  static styles = css`
    * {
      margin: 0;
      padding: 0;
      border: 0;
      box-sizing: border-box;
    }

    *,
    ::before,
    ::after {
      box-sizing: border-box;
      border-width: 0;
      border-style: solid;
      border-color: #ececec;
    }

    button,
    input,
    select,
    textarea {
      margin: 0;
      padding: 0;
      border: 0;
      background: transparent;
      font-size: inherit;
      line-height: inherit;
      outline: none;
    }

    .input-field {
      position: relative;
      display: inline-block;
      font-family: var(
        --pure-material-font,
        "Roboto",
        "Segoe UI",
        BlinkMacSystemFont,
        system-ui,
        -apple-system
      );
      font-size: 16px;
      line-height: 1.5;
      overflow: hidden;
      border: 1px solid #7f7f7f;
      border-radius: 4px;
    }

    .input-field:focus-within {
      outline: 0;
      border: 2px solid #3570d2;
    }

    /* Input, Textarea */
    .input-field > input,
    .input-field > textarea,
    .input-field > div > input,
    .input-field > div > textarea {
      display: block;
      box-sizing: border-box;
      margin: 0;
      border-top: solid 27px transparent;
      padding: 0 12px 10px;
      width: 100%;
      height: inherit;
      color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
      background-color: #ffffff;
      box-shadow: none; /* Firefox */
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      caret-color: #3570d2;
      transition: border-bottom 0.2s, background-color 0.2s;
    }

    /* Span */
    .input-field > input + span,
    .input-field > textarea + span,
    .input-field > div > input + span,
    .input-field > div > textarea + span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      box-sizing: border-box;
      padding: 7px 12px 0;
      color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
      font-size: 75%;
      line-height: 18px;
      pointer-events: none;
      transition: color 0.2s, font-size 0.2s, line-height 0.2s;
    }

    /* Placeholder-shown */
    .input-field > input:not(:focus):placeholder-shown + span,
    .input-field > textarea:not(:focus):placeholder-shown + span,
    .input-field > div > input:not(:focus):placeholder-shown + span,
    .input-field > div > textarea:not(:focus):placeholder-shown + span {
      font-size: inherit;
      line-height: 48px;
    }

    /* Focus */
    .input-field > input:focus,
    .input-field > textarea:focus,
    .input-field > div > input:focus,
    .input-field > div > textarea:focus {
      outline: none;
    }

    .input-field > input:focus + span,
    .input-field > textarea:focus + span,
    .input-field > div > input:focus + span,
    .input-field > div > textarea:focus + span {
      color: #3570d2;
    }

    .input-field > input:focus + span::before,
    .input-field > textarea:focus + span::before,
    .input-field > div > input:focus + span::before,
    .input-field > div > textarea:focus + span::before {
      opacity: 0.12;
    }

    .input-field > input:focus + span::after,
    .input-field > textarea:focus + span::after,
    .input-field > div > input:focus + span::after,
    .input-field > div > textarea:focus + span::after {
      transform: scale(1);
    }

    /* Disabled */
    .input-field > input:disabled,
    .input-field > textarea:disabled,
    .input-field > div > input:disabled,
    .input-field > div > textarea:disabled {
      border-bottom-color: rgba(
        var(--pure-material-onsurface-rgb, 0, 0, 0),
        0.38
      );
      color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
      background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.24);
    }

    .input-field > input:disabled + span,
    .input-field > textarea:disabled + span,
    .input-field > div > input:disabled + span,
    .input-field > div > textarea:disabled + span {
      color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    }

    .input-field.error {
      border-color: #d0021b;
    }

    /* Faster transition in Safari for less noticable fractional font-size issue */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        .input-field > input,
        .input-field > input + span,
        .input-field > input + span::after,
        .input-field > textarea,
        .input-field > textarea + span,
        .input-field > textarea + span::after {
          transition-duration: 0.1s;
        }

        .input-field > div > input,
        .input-field > div > input + span,
        .input-field > div > input + span::after,
        .input-field > div > textarea,
        .input-field > div > textarea + span,
        .input-field > div > textarea + span::after {
          transition-duration: 0.1s;
        }
      }
    }

    .input-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .input-group > .error-message {
      display: none;
      font-size: 12px;
      margin: 0;
      font-weight: 400;
      line-height: 18px;
      color: #d0021b;
    }

    :host {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: calc(100vh - 45px);
    }

    .card-container {
      display: flex;
      flex-direction: column;
      padding: 32px 60px;
      gap: 32px;
      background: #ffffff;
      border: 1px solid #ececec;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02),
        0px 20px 40px rgba(0, 0, 0, 0.06);
      border-radius: 12px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      padding: 0 16px;
    }

    .card-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #424242;
    }

    a {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #3570d2;
      text-decoration: none;
    }

    .card-footer {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
    .card-footer .submit {
      color: white;
      padding: 12px 16px;
      width: 328px;
      height: 48px;
      background: #3570d2;
      border-radius: 8px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      cursor: pointer;
      border: none;
    }

    .card-footer .submit:hover {
      background: #1652b5;
    }

    .card-footer .submit:active {
      background: #003a9e;
    }

    a:hover {
      color: #1652b5;
    }

    a:active {
      color: #003a9e;
    }

    .card-footer a {
      width: 328px;
      height: 40px;
      padding: 12px 16px;
    }

    .button-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
    }

    .submit {
      background-color: #3570d2;
      padding: 12px 16px;
      border-radius: 8px;
      width: 100%;
      color: #ffffff;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      text-align: center;
      margin-top: 20px;
      cursor: pointer;
    }

    .register {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
    }

    .error {
      font-size: 14px;
      line-height: 20px;
      color: #d0021b;
    }
  `;

  connectedCallback() {
    super.connectedCallback();
    this.csrf = {
      ...this.csrf,
      param: document.querySelector('head > meta[name="csrf-param"]')?.content,
      token: document.querySelector('head > meta[name="csrf-token"]')?.content,
    };
  }

  render() {
    return html`
      <div class="card-container">
        <h1 class="card-title">Alo! Selamat datang di Aloproteksi</h1>
        <form class="card-body" method="post" action="${this.loginUrl}">
          <input
            type="hidden"
            name="${this.csrf["param"]}"
            value="${this.csrf["token"]}"
          />
          <div class="input-group email">
            <label class="input-field">
              <input name="auth[email]" placeholder=" " type="text" />
              <span>Alamat Email</span>
            </label>
          </div>

          <div class="input-group password">
            <label class="input-field">
              <input name="auth[password]" placeholder=" " type="password" />
              <span>Password</span>
            </label>
          </div>

          ${this.data?.status === "error"
            ? html`<p class="error">Kombinasi email dan password salah.</p>`
            : nothing}

          <a href="/reset-password">Lupa Kata Sandi?</a>

          <div class="button-group">
            <button class="submit" type="submit">Masuk</button>
            <a class="register" href="${this.corporateLandingUrl}/simulations">
              Daftar Akun
            </a>
          </div>
        </form>
      </div>
    `;
  }
}

customElements.define("login-form", LoginForm);
